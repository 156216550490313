<template>
  <button class="btn btn-1 btn-primary" @click="$router.push('/signup')">
    SIGN UP
  </button>
</template>

<script>
export default {
  name: "MyTopBarButtonSignUp",
};
</script>
