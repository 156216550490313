<template>
  <div class="landing-page top-bar-after">
    <h1>{{ $store.state.title }}</h1>
    <div class="ctn ctn-one-line">
      <h2>Use</h2>
      <div class="landing-page-little-card">
        <h1 class="logo">CARDs</h1>
      </div>
    </div>
  </div>
  <div class="ctn ctn-wave-and-button">
    <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#A1A1A1"
        fill-opacity="1"
        d="M0,128L30,149.3C60,171,120,213,180,197.3C240,181,300,107,360,96C420,85,480,139,540,181.3C600,224,660,256,720,234.7C780,213,840,139,900,133.3C960,128,1020,192,1080,186.7C1140,181,1200,107,1260,85.3C1320,64,1380,96,1410,112L1440,128L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
      ></path>
    </svg>
    <button
      class="btn btn-2 btn-primary btn-wave-sign-up"
      @click="$router.push('/signup')"
    >
      Sign up
    </button>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  async mounted() {
    if (this.$store.state.signedIn) {
      this.$store.dispatch("changeRoute", {
        route: "/allcards",
        name: "All Cards",
      });
    }
    if (await this.$store.dispatch("verifyJWT")) {
      this.$store.dispatch("changeRoute", {
        route: "/allcards",
        name: "All Cards",
      });
    }
    this.$store.dispatch("getTitle");
  },
};
</script>
